<template>
  <v-container
    :fluid="$vuetify.breakpoint.lgAndUp"
    class="fill-height d-flex flex-column"
    style="height: 90vh"
  >
    <v-row class="ma-0 pa-0 mb-6" align="center">
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="5"
        sm="12"
        class="d-flex flex-column align-center pt-0"
      >
        <v-img
          v-if="isCreditCardPayment"
          width="500"
          :src="creditCardImage"
          contain
        />
        <v-img v-else width="500" :src="successContractImage" contain />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="8" xl="7" class="d-flex flex-column">
        <v-card-title
          class="text-h5 font-weight-bold d-flex justify-center break-word text-center primary--text text--accent-4"
        >
          {{ $t(title) }}
        </v-card-title>
        <v-list>
          <v-list-item v-for="(item, index) in featuredList" :key="index">
            <v-list-item-icon>
              <v-icon
                v-text="item.icon ? item.icon : 'ph-fill ph-check-circle'"
                size="25"
                color="success"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="break-word text-wrap normal-line-height"
                v-html="item.title"
              />
              <v-list-item-subtitle
                v-if="item.subtitle"
                v-html="item.subtitle"
                class="break-word text-wrap normal-line-height"
              />
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isIntrestedInService">
            <v-list-item-icon>
              <v-icon
                v-text="'ph-fill ph-check-circle'"
                size="25"
                color="success"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="break-word text-wrap normal-line-height"
                v-html="$t('selfCheckout.implementServiceContact')"
              />
              <v-list-item-subtitle
                v-html="$t('selfCheckout.implementServiceContactDescription')"
                class="break-word text-wrap normal-line-height"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-banner
          v-if="!displayMobile"
          single-line
          transition="slide-y-transition"
          style="cursor: pointer"
          outlined
          class="rounded-lg"
        >
          <v-avatar slot="icon" color="primary" size="40">
            <v-icon icon="ph-fill ph-lock" color="white">
              ph-fill ph-chat-centered
            </v-icon>
          </v-avatar>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="primary--text font-weight-medium break-word text-wrap normal-line-height"
              >
                {{ $t("selfCheckout.helpWithPaymentText") }}
              </v-list-item-title>

              <v-list-item-subtitle
                class="break-word text-wrap normal-line-height"
              >
                <p
                  class="break-word text-wrap normal-line-height"
                  v-html="$t('selfCheckout.accessPageSuportText')"
                ></p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <template v-slot:actions>
            <v-btn
              color="primary"
              class="text-none text-body-2 font-weight-medium px-6 lighten-2"
              @click="redirectToSupportPage()"
            >
              {{ $t("common.help") }}
              <v-icon class="arrow-left-transition ml-4" right size="18">
                ph-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </v-banner>

        <div v-if="displayMobile" @click="redirectToSupportPage()">
          <v-banner
            single-line
            transition="slide-y-transition"
            style="cursor: pointer"
            outlined
            class="rounded-lg"
          >
            <v-avatar slot="icon" color="primary" size="40">
              <v-icon icon="ph-fill ph-lock" color="white">
                ph-fill ph-chat-centered
              </v-icon>
            </v-avatar>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text font-weight-medium break-word text-wrap normal-line-height"
                >
                  {{ $t("selfCheckout.helpWithPaymentText") }}
                </v-list-item-title>

                <v-list-item-subtitle
                  class="break-word text-wrap normal-line-height"
                >
                  <p
                    class="break-word text-wrap normal-line-height"
                    v-html="$t('selfCheckout.accessPageSuportText')"
                  ></p>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-banner>
        </div>

        <v-card-actions
          :class="` mt-6 ${$vuetify.breakpoint.smAndDown ? 'flex-column' : ''}`"
        >
          <v-btn
            v-if="isCreditCardPayment"
            x-large
            :block="$vuetify.breakpoint.xs"
            color="primary lighten-2"
            :class="`text-none text-body-1 font-weight-medium pr-8 pl-5  ${
              $vuetify.breakpoint.smAndDown ? 'mb-4' : 'mr-4'
            }`"
            @click="primaryAction"
          >
            <v-icon v-text="primaryActionIcon" left size="20" class="mr-4" />
            {{ primaryActionText }}
          </v-btn>
          <v-spacer v-else></v-spacer>

          <v-btn
            x-large
            :block="$vuetify.breakpoint.xs"
            outlined
            color="primary lighten-2"
            class="text-none text-body-1 font-weight-medium"
            @click="secondaryAction"
          >
            {{ secondaryActionText }}
            <v-icon
              v-text="'ph-arrow-right'"
              class="arrow-left-transition ml-4"
              size="20"
              right
            />
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics";
import { openSuperLogicaClientArea } from "@/helpers/services/selfCheckout";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "HelpWithPayment",
  props: {
    title: {
      type: String,
      default: "selfCheckout.almostThere",
    },
    featuredList: { type: Array, default: () => [] },
    isCreditCardPayment: { type: Boolean, default: false },
  },

  data() {
    return {
      creditCardImage: require("@/assets/images/credit_card_payment.png"),
      successContractImage: require("@/assets/images/contract-plan.svg"),
    };
  },

  computed: {
    ...mapGetters(["mainDomain", "isIntrestedInService"]),

    primaryActionText() {
      return this.$t("billing.goToSuperLogica");
    },

    secondaryActionText() {
      return this.$t("hirePlan.goBackToPlans");
    },

    primaryActionIcon() {
      return "ph-fill ph-list-bullets";
    },

    displayMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    ...mapMutations(["setSidebarMini", "setActionFeedBackMode"]),

    openSuperLogicaClientArea,

    primaryAction() {
      this.openSuperLogicaClientArea();
    },

    secondaryAction() {
      setFirebaseEvent(
        this.mainDomain,
        "go_to_self_checkout",
        "after_using_credit_card_payment"
      );
      this.$emit("backToPlans");
    },

    redirectToSupportPage() {
      return this.$router.push({ name: "ContactUs" });
    },
  },

  mounted() {
    this.setActionFeedBackMode(true);
    this.setSidebarMini(true);
  },

  beforeDestroy() {
    this.setActionFeedBackMode(false);
  },
};
</script>
