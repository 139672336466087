<template>
  <v-item-group mandatory v-model="paymentMethod" class="mb-5">
    <v-subheader class="font-weight-bold">
      {{ $t("selfCheckout.paymentType") }}

      {{
        `(${
          selfCheckoutSubscriptionType == "FLEX"
            ? $t("hirePlan.monthlyPayment")
            : $t("hirePlan.annualPayment")
        })`
      }}
    </v-subheader>

    <v-row class="pa-0 ma-0 mb-5">
      <v-col
        v-for="(paymentMethod, index) in paymentMethods"
        :key="index"
        :class="`py-0 px-0 ${getPadding(index)}`"
        lg="6"
      >
        <v-item
          v-if="paymentMethod.show"
          v-slot="{ active, toggle }"
          :value="paymentMethod.value"
        >
          <v-card
            outlined
            :color="active ? 'primary lighten-2' : ''"
            :dark="active"
            :class="`d-flex align-center px-4 ${
              $vuetify.breakpoint.lg
                ? 'flex-column justify-center text-center fill-height'
                : ''
            }`"
            :width="'100%'"
            @click="toggle"
            :disabled="selfChekoutTotalPrice === 0"
          >
            <v-icon
              size="45"
              :color="active ? 'white' : 'secondary lighten-1'"
              :class="`${$vuetify.breakpoint.lg ? 'mt-3' : ''}`"
              v-text="paymentMethod.icon"
            />

            <div class="pb-3 pt-5 mx-3">
              <v-card-title
                :class="`text-sm-body-1 font-weight-bold my-0 py-0 text-wrap break-word ${
                  active ? 'white--text' : 'secondary--text text--lighten-1'
                } ${
                  $vuetify.breakpoint.lg ? 'd-flex justify-center mb-3' : ''
                }`"
              >
                {{ paymentMethod.title }}
              </v-card-title>

              <v-subheader
                v-if="paymentMethod.description"
                :class="`caption font-weight-regular py-0 my-0 normal-line-height ${
                  active ? 'white--text' : 'secondary--text text--lighten-1'
                }`"
              >
                {{ paymentMethod.description }}
              </v-subheader>
            </div>
          </v-card>
        </v-item>
      </v-col>
    </v-row>
  </v-item-group>
</template>
<script>
import { planPeriodicDescription } from "@/helpers/variables/translateString";
import { getAnalytics, logEvent } from "firebase/analytics";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SelectPaymentType",

  data() {
    return {
      planPeriodicDescription,
    };
  },

  computed: {
    ...mapGetters([
      "selfCheckoutSubscriptionType",
      "selfCheckoutPaymentMethod",
      "selfChekoutTotalPrice",
      "currentUser",
      "mainDomain",
      "maxValueAllowedForCardPayment",
    ]),

    paymentMethod: {
      get() {
        return this.selfCheckoutPaymentMethod;
      },
      set(value) {
        this.setSelfCheckoutPaymentMethod(value);
      },
    },

    paymentMethods() {
      return [
        {
          icon: "ph-credit-card",
          title: this.$t("selfCheckout.creditCard"),
          description: this.$t("selfCheckout.creditCardPaymentInstructions"),
          value: "credit_card",
          show: this.selfChekoutTotalPrice < this.maxValueAllowedForCardPayment,
        },
        {
          icon: "ph-ticket",
          title: this.$t("selfCheckout.bankSlip"),
          description: this.$t("selfCheckout.receiveBankSlipEmail"),
          value: "ticket",
          show: true,
        },
      ].filter((paymentMethod) => paymentMethod.show);
    },
  },

  watch: {
    method() {
      this.setPaymentMethod(this.method);
    },

    paymentMethod() {
      this.sendFirebaseEvents(this.paymentMethod);
    },
  },

  methods: {
    ...mapMutations(["setSelfCheckoutPaymentMethod"]),

    getPadding(index) {
      const lgAndUp = this.$vuetify.breakpoint.lgAndUp;

      if (lgAndUp) {
        return `${index > 0 ? "pr-0 pl-4" : "pl-0 pr-4"}`;
      } else {
        return "pb-5";
      }
    },

    sendFirebaseEvents(payment_type) {
      const analytics = getAnalytics();
      logEvent(analytics, "self-checkout", {
        click: "payment_type",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: `Selecionou o método de pagamento ${payment_type} no self-checkout`,
      });
    },
  },
  beforeMount() {
    this.setSelfCheckoutPaymentMethod("ticket");
  },
};
</script>
