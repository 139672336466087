var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('v-container',{staticClass:"pa-0 ma-0 text-center"},[_c('v-overlay',{attrs:{"value":true,"opacity":"1","z-index":"200","color":_vm.error ? 'error' : 'primary'}},[_c('v-card',{staticClass:"d-flex flex-column align-center",attrs:{"flat":"","color":"transparent","tile":"","max-width":"850"}},[_c('div',{staticClass:"mb-5"},[_c('Logo',{staticClass:"blink",attrs:{"product":"conecta_suite","height":"65","no-margins":"","white":""}})],1),_c('v-scroll-y-reverse-transition',[(_vm.error)?_c('v-card-actions',{class:`text-h5 font-weight-bold pt-6 px-0 text-wrap break-word ${
            _vm.$vuetify.breakpoint.xs ? 'd-flex flex-column' : ''
          }`},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1),_c('v-scroll-y-reverse-transition',[(_vm.isCreatingPlan)?_c('v-card-actions',{class:`text-h5 font-weight-bold pt-6 px-0 text-wrap break-word ${
            _vm.$vuetify.breakpoint.xs ? 'd-flex flex-column' : ''
          }`},[_vm._v(" "+_vm._s(_vm.$t("purchaseLoading.savingPlanInfo"))+" "),_c('v-fade-transition',[(_vm.showCheckbox)?_c('v-icon',{staticClass:"ml-4",attrs:{"size":"32","color":"white"},domProps:{"textContent":_vm._s('ph-fill ph-check-circle')}}):_vm._e()],1),(!_vm.showCheckbox)?_c('v-progress-circular',{staticClass:"ml-4",attrs:{"width":"3","size":"30","color":"white","indeterminate":""}}):_vm._e()],1):_vm._e()],1),(!_vm.error)?_c('div',[_c('v-scroll-y-reverse-transition',[(_vm.startingCountdown)?_c('div',[_c('v-card-actions',{class:`text-h5 font-weight-bold pt-6 px-0 text-wrap break-word ${
                _vm.$vuetify.breakpoint.xs ? 'd-flex flex-column' : ''
              }`},[_vm._v(" "+_vm._s(_vm.$t(_vm.loadingMessage))+" "),_c('v-fade-transition',[(_vm.timerNumber < 1)?_c('v-icon',{staticClass:"ml-4",attrs:{"size":"32","color":"white"},domProps:{"textContent":_vm._s('ph-fill ph-check-circle')}}):_vm._e()],1),(_vm.timerNumber > 0)?_c('v-progress-circular',{staticClass:"ml-4",attrs:{"width":"3","size":"30","color":"white","indeterminate":""}}):_vm._e()],1),_c('v-list-item-subtitle',{staticClass:"normal-line-height text-wrap break-word text-body-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t("purchaseLoading.redirectingIn"))+" "+_vm._s(_vm.timerNumber)+" "+_vm._s(_vm.timerNumber === 1 ? _vm.$t("common.second") : _vm.$t("common.seconds"))+" ")])],1):_vm._e()])],1):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }