<template>
  <div>
    <v-slide-x-transition>
      <span
        v-if="selfCheckoutDiscount > 0 && selfCheckoutTotalPriceWithoutDiscount"
        class="text-h6 font-weight-light text-decoration-line-through"
      >
        {{ formatMoney(selfCheckoutTotalPriceWithoutDiscount) }}
      </span>
    </v-slide-x-transition>

    <v-card-actions
      v-if="selfChekoutTotalPrice || isBasicFreeSelected"
      class="px-0 align-end"
    >
      <!-- TOTAL -->
      <span class="text-h4 font-weight-bold primary--text text--lighten-2">
        {{ formatMoney(selfChekoutTotalPrice) }}
      </span>

      <!-- período -->
      <span class="text-body-2 ml-1 mb-1" v-if="!isBasicFreeSelected">
        {{
          `/${
            selfCheckoutSubscriptionType == "FLEX"
              ? $t("periodicity.month")
              : $t("periodicity.year")
          }`
        }}
      </span>
    </v-card-actions>

    <v-card-actions v-else class="px-0">
      <span class="text-h6 font-weight-bold error--text">
        <v-icon v-text="'ph-smiley-x-eyes'" color="error" class="mr-1" />
        {{ $t("errorMessages.totalCalc") }}
      </span>
    </v-card-actions>

    <!-- DESCONTO -->
    <v-slide-x-transition>
      <v-chip
        v-if="selfCheckoutDiscount > 0 && selfCheckoutDiscountInMoney"
        color="success"
        class="mb-1 font-weight-bold"
        label
      >
        {{
          $t("hirePlan.economyOf") + formatMoney(selfCheckoutDiscountInMoney)
        }}
      </v-chip>
    </v-slide-x-transition>

    <strong
      class="my-2 d-block"
      v-if="isIntrestedInService && !isBasicFreeSelected && selfCheckoutService"
    >
      {{
        `+ ${formatMoney(selfCheckoutService.price)} ${$t(
          "selfCheckout.implementService"
        )} (${$t("selfCheckout.oneTimeCharge")})`
      }}
    </strong>
  </div>
</template>
<script>
import { formatMoney } from "@/helpers/services/utils";
import { translatePeriods } from "@/helpers/variables/translateString";
import { mapGetters } from "vuex";

export default {
  name: "TotalPlanPrice",

  data() {
    return {
      translatePeriods,
    };
  },

  computed: {
    ...mapGetters([
      "selfCheckoutDiscount",
      "selfCheckoutTotalPriceWithoutDiscount",
      "selfChekoutTotalPrice",
      "selfCheckoutSubscriptionType",
      "selfCheckoutDiscountInMoney",
      "isBasicFreeSelected",
      "isIntrestedInService",
      "selfCheckoutService",
    ]),
  },

  methods: {
    formatMoney,
  },
};
</script>
