<template>
  <v-container class="pa-0 ma-0 text-center" v-if="show">
    <v-overlay
      :value="true"
      opacity="1"
      z-index="200"
      :color="error ? 'error' : 'primary'"
    >
      <v-card
        flat
        color="transparent"
        tile
        max-width="850"
        class="d-flex flex-column align-center"
      >
        <!-- LOGO DA APLICAÇÃO -->
        <div class="mb-5">
          <Logo
            product="conecta_suite"
            height="65"
            no-margins
            white
            class="blink"
          />
        </div>

        <!-- TEXTO EXIBIDO EM CASO DE ERRO -->
        <v-scroll-y-reverse-transition>
          <v-card-actions
            v-if="error"
            :class="`text-h5 font-weight-bold pt-6 px-0 text-wrap break-word ${
              $vuetify.breakpoint.xs ? 'd-flex flex-column' : ''
            }`"
          >
            {{ errorMessage }}
          </v-card-actions>
        </v-scroll-y-reverse-transition>

        <!-- TEXTO EXIBIDO ENQUANTO ESTÁ SALVANDO O PLANO -->
        <v-scroll-y-reverse-transition>
          <v-card-actions
            v-if="isCreatingPlan"
            :class="`text-h5 font-weight-bold pt-6 px-0 text-wrap break-word ${
              $vuetify.breakpoint.xs ? 'd-flex flex-column' : ''
            }`"
          >
            {{ $t("purchaseLoading.savingPlanInfo") }}

            <v-fade-transition>
              <v-icon
                v-if="showCheckbox"
                v-text="'ph-fill ph-check-circle'"
                size="32"
                color="white"
                class="ml-4"
              />
            </v-fade-transition>

            <v-progress-circular
              v-if="!showCheckbox"
              width="3"
              size="30"
              color="white"
              indeterminate
              class="ml-4"
            />
          </v-card-actions>
        </v-scroll-y-reverse-transition>

        <div v-if="!error">
          <!-- TEXTO EXIBIDO ANTES DO REDIRECIONAMENTO -->
          <v-scroll-y-reverse-transition>
            <div v-if="startingCountdown">
              <v-card-actions
                :class="`text-h5 font-weight-bold pt-6 px-0 text-wrap break-word ${
                  $vuetify.breakpoint.xs ? 'd-flex flex-column' : ''
                }`"
              >
                {{ $t(loadingMessage) }}
                <v-fade-transition>
                  <v-icon
                    v-if="timerNumber < 1"
                    v-text="'ph-fill ph-check-circle'"
                    size="32"
                    color="white"
                    class="ml-4"
                  />
                </v-fade-transition>

                <v-progress-circular
                  v-if="timerNumber > 0"
                  width="3"
                  size="30"
                  color="white"
                  indeterminate
                  class="ml-4"
                />
              </v-card-actions>

              <v-list-item-subtitle
                class="normal-line-height text-wrap break-word text-body-1 mt-3"
              >
                {{ $t("purchaseLoading.redirectingIn") }} {{ timerNumber }}
                {{
                  timerNumber === 1 ? $t("common.second") : $t("common.seconds")
                }}
              </v-list-item-subtitle>
            </div>
          </v-scroll-y-reverse-transition>
        </div>
      </v-card>
    </v-overlay>
  </v-container>
</template>
<script>
import { openSuperLogicaClientArea } from "@/helpers/services/selfCheckout";

export default {
  name: "PurchaseLoading",

  props: {
    creatingPlan: { type: Boolean, default: true, required: true },
    createdPlan: { type: Boolean, default: false },
    isCreditCardPayment: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: {
      type: String,
      default: "this.$t('errorMessages.registerPlan')",
    },
    show: { type: Boolean, default: false },
  },

  data() {
    return {
      timerInterval: null,
      timerNumber: 6,

      showCheckbox: false,
      startCountdown: false,
    };
  },

  computed: {
    showCountdown() {
      return this.startCountdown;
    },

    isCreatingPlan() {
      return this.creatingPlan || this.createdPlan;
    },

    startingCountdown() {
      return this.startCountdown;
    },

    loadingMessage() {
      return this.isCreditCardPayment
        ? "purchaseLoading.youBeeingRedirected"
        : "purchaseLoading.redirectingIn";
    },
  },

  watch: {
    async createdPlan() {
      if (this.createdPlan) {
        this.resetTimer();
        this.showCheckbox = true;

        await setTimeout(() => {
          this.$emit("reset-created-plan");
          this.showCheckbox = false;
          this.startCountdown = true;
          this.setTimer();
        }, 2000);
      }
    },

    error() {
      if (this.error) {
        setTimeout(() => {
          this.$emit("reset-error-state");
        }, 5000);
      }
    },
  },

  methods: {
    openSuperLogicaClientArea,

    decrementTimer() {
      if (this.timerNumber > 0) {
        this.timerNumber--;
      } else {
        this.resetTimer();
      }
    },

    resetTimer() {
      clearInterval(this.timerInterval);
      this.timerNumber = 6;
    },

    setTimer() {
      if (this.startCountdown) {
        this.timerInterval = setInterval(this.decrementTimer, 1000);

        setTimeout(() => {
          this.startCountdown = false;
          this.$emit("hide");
          if (this.isCreditCardPayment) {
            openSuperLogicaClientArea();
          }
        }, 6000);
      }
    },
  },

  beforeDestroy() {
    this.resetTimer();
  },
};
</script>
