<template>
  <FullscreenStepsDialog
    :show="true"
    :title="$t('selfCheckout.confirmNewPlan')"
    :steps="steps"
    :current-step="step"
    :linear="true"
    :show-steps="false"
    @update:current-step="step = $event"
    @close="close"
    :show-divider="false"
  >
    <!-- Are you sure? -->
    <template #stepper-content.1>
      <v-container
        style="height: 100%"
        class="d-flex justify-center align-center flex-column"
      >
        <v-row no-gutters style="width: 90vw">
          <v-col
            :class="`d-flex ${
              $vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'
            } align-center`"
          >
            <v-img :src="situationImage" width="100%" max-width="510px" />
          </v-col>
          <v-col
            :class="`d-flex ${
              $vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-start'
            } align-center`"
          >
            <div
              :class="`${$vuetify.breakpoint.smAndDown ? 'mx-10' : 'ma-10'}`"
              style="line-height: 1.25; max-width: 510px"
            >
              <h1 class="line">
                {{ $t("selfCheckout.confirmingNewPlan.youAreAboutToLose") }}
              </h1>

              <BenefitsList
                :benefits="
                  benefitsDiff.filter((benefit) => benefit.difference != 'plus')
                "
              />
            </div>
          </v-col>
        </v-row>

        <div class="d-flex">
          <v-btn
            height="58"
            :class="`mb-10 mx-3 text-none text-h6 font-weight-bold px-8 ${
              $vuetify.breakpoint.smAndDown ? 'mt-10' : ''
            }`"
            large
            color="accent"
            @click="close"
          >
            <v-icon size="24" left dark>ph-arrow-left</v-icon>
            {{ $t("selfCheckout.confirmingNewPlan.wantBenefits") }}
          </v-btn>
          <v-spacer />
          <v-btn
            height="58"
            :class="`mb-10 mx-3 text-none text-h6 font-weight-bold px-8 ${
              $vuetify.breakpoint.smAndDown ? 'mt-10' : ''
            }`"
            large
            color="accent"
            outlined
            @click="goToNextStep"
            >{{ $t("selfCheckout.confirmingNewPlan.dontWantBenefits") }}
            <v-icon size="24" right dark>ph-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-container>
    </template>
    <template #stepper-content.2>
      <v-container>
        <h1 class="line">
          {{ $t("selfCheckout.confirmingNewPlan.beforeContinuing") }}
        </h1>

        <h3 class="my-3 font-weight-regular">
          {{ $t("selfCheckout.confirmingNewPlan.howDoYouRate") }}
        </h3>

        <FeelingRatingPicker
          :selected="rating"
          @update="rating = $event"
          class="mt-5"
          style="max-width: fit-content"
        />

        <h3 class="my-3 font-weight-regular">
          {{ $t("selfCheckout.confirmingNewPlan.tellUsTheReason") }}
        </h3>

        <v-form>
          <v-row no-gutters>
            <v-col>
              <v-treeview
                ref="treeview"
                v-model="churnSelected"
                selectable
                class="cursor"
                :items="churnItems"
                return-object
                hoverable
                open-all
                color="primarySuite"
              ></v-treeview>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="otherReason"
                :label="$t('selfCheckout.churnReasons.otherReason')"
                class="mt-3 ms-10"
                outlined
                style="max-width: 500px"
              />
            </v-col>
          </v-row>
        </v-form>

        <div class="d-flex">
          <v-btn
            height="58"
            class="px-8 mt-10 mb-10 text-none text-h6 font-weight-bold"
            large
            color="accent"
            @click="close"
          >
            <v-icon size="24" left dark>ph-prohibit</v-icon>
            {{ $t("action.cancel") }}
          </v-btn>
          <v-spacer />
          <v-btn
            height="58"
            class="px-8 mt-10 mb-10 me-3 text-none text-h6 font-weight-bold"
            large
            color="accent"
            text
            @click="goToNextStep"
          >
            {{ $t("action.skip") }}
            <v-icon size="24" right dark>ph-arrow-right</v-icon>
          </v-btn>
          <v-btn
            height="58"
            class="px-8 mt-10 mb-10 text-none text-h6 font-weight-bold"
            large
            color="accent"
            outlined
            :disabled="!filled || loading"
            :loading="loading"
            @click="sendFeedback"
          >
            {{ $t("action.send") }}
            <v-icon size="24" right dark>ph-check</v-icon>
          </v-btn>
        </div>
      </v-container>
    </template>
    <!-- Reason -->
    <template #stepper-content.3>
      <v-container>
        <v-row no-gutters dense class="align-center ma-0 pa-0 mb-2">
          <v-col
            :cols="$vuetify.breakpoint.xs ? 12 : 5"
            class="pa-0"
            :align="$vuetify.breakpoint.xs ? 'center' : 'right'"
          >
            <NewComparativePlan
              :company-plan="actualCompanyPlan"
              :color="'success lighten-2'"
              :benefits="benefitsActual"
            ></NewComparativePlan>
          </v-col>

          <v-col
            :cols="$vuetify.breakpoint.xs ? 12 : 2"
            :class="`pa-0 ${$vuetify.breakpoint.xs ? 'mt-2' : ''}`"
            align="center"
            justify="center"
          >
            <v-icon
              class="mx-auto"
              v-text="
                $vuetify.breakpoint.xs
                  ? 'ph-bold ph-arrow-down'
                  : 'ph-bold ph-arrow-right'
              "
              :size="24"
              right
            />
          </v-col>

          <v-col
            :cols="$vuetify.breakpoint.xs ? 12 : 5"
            :class="`py-0 ${$vuetify.breakpoint.xs ? 'mt-2' : ''}`"
            :align="$vuetify.breakpoint.xs ? 'center' : 'left'"
          >
            <NewComparativePlan
              :company-plan="newCompanyPlan"
              :is-new-plan="true"
              :nextBilling="
                hasBasicInTrial
                  ? formatDateForDatabase(today)
                  : orderNextBilling
              "
              :benefits="benefitsDiff"
            ></NewComparativePlan>
          </v-col>
        </v-row>

        <v-card-text class="px-center pt-4 pb-0 mb-1" style="font-size: 18px">
          <Alert color="warning" class="mb-0">
            {{ $t("selfCheckout.overwriteCurrentPlanAlert") }}
          </Alert>
        </v-card-text>

        <div class="d-flex">
          <v-btn
            height="58"
            class="px-8 mt-10 mb-10 text-none text-h6 font-weight-bold"
            large
            color="accent"
            @click="close"
          >
            <v-icon size="24" left dark>ph-prohibit</v-icon>
            {{ $t("action.cancel") }}
          </v-btn>
          <v-spacer />
          <v-btn
            height="58"
            class="px-8 mt-10 mb-10 text-none text-h6 font-weight-bold"
            large
            color="accent"
            outlined
            @click="() => $emit('hire-new-plan')"
          >
            {{ $t("selfCheckout.confirmPlanPurchaseText") }}
            <v-icon size="24" right dark>ph-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-container>
    </template>
    <!-- Done -->
    <!-- <template #stepper-content.4></template> -->
  </FullscreenStepsDialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import FeelingRatingPicker from "@/components/forms/field_pickers/FeelingRatingPicker.vue";
import FullscreenStepsDialog from "@/components/main/shared/FullscreenStepsDialog.vue";
import BenefitsList from "@/components/purchase/selfcheckout/BenefitsList.vue";
import NewComparativePlan from "@/components/purchase/selfcheckout/NewComparativePlan.vue";
import {
  addDays,
  formatDateForDatabase,
  greaterOrEqualToday,
  today,
} from "@/helpers/services/utils";
import { planFeaturesDifferences } from "@/helpers/variables/productsDetails";

export default {
  name: "ConfirmNewPlan",

  components: {
    FullscreenStepsDialog,
    FeelingRatingPicker,
    NewComparativePlan,
    BenefitsList,
  },

  props: {
    actualCompanyPlan: { type: Object, default: () => {} },
    newCompanyPlan: { type: Object, default: () => {} },
  },

  data: () => ({
    downgradeImage: require("@/assets/images/selfcheckout-downgrade.png"),

    step: 1,
    steps: [{ id: 1 }, { id: 2 }, { id: 3 }],
    nextStep: {
      1: 2,
      2: 3,
      3: 3,
    },

    loading: false,

    benefitDifference: {
      minus: {
        color: "error",
        icon: "ph-x-circle",
      },
      zero: {
        color: "gray",
        icon: "ph-caret-right-circle",
      },
      plus: {
        color: "success",
        icon: "ph-fill ph-check-circle",
      },
    },

    rating: null,
    churnSelected: [],
    otherReason: "",
  }),

  computed: {
    today,
    formatDateForDatabase,
    ...mapGetters([
      "hasBasicInTrial",
      "impactedCompanyPlansInTrial",
      "token",
      "serviceProvider",
    ]),
    situationImage() {
      return this.downgradeImage;
    },
    actualPlanName() {
      return this.actualCompanyPlan?.plan?.name;
    },
    newPlanName() {
      return this.newCompanyPlan?.plan?.name;
    },
    actualPlanSku() {
      return this.actualCompanyPlan?.plan?.sku;
    },
    newPlanSku() {
      return this.newCompanyPlan?.plan?.sku;
    },
    skuDifferences() {
      return this.planFeaturesDifferences(
        this.actualPlanSku,
        this.newPlanSku,
        this.serviceProvider
      );
    },
    benefitsDiff() {
      return this.skuDifferences.map((benefit) => ({
        ...benefit,
        ...this.benefitDifference[benefit.difference],
      }));
    },
    benefitsActual() {
      return this.benefitsDiff.map((benefit) => ({
        ...benefit,
        ...this.benefitDifference.plus,
      }));
    },
    nearestEndTrial() {
      return this.impactedCompanyPlansInTrial
        .map((companyPlan) => companyPlan.end_trial)
        .sort((a, b) => new Date(b) - new Date(a));
    },
    orderNextBilling() {
      if (this.nearestEndTrial.length) {
        return greaterOrEqualToday(this.nearestEndTrial[0])
          ? formatDateForDatabase(addDays(this.nearestEndTrial[0], 1))
          : formatDateForDatabase(today);
      }

      return formatDateForDatabase(today);
    },
    churnItems() {
      return [
        "selfCheckout.churnReasons.tooExpensive",
        "selfCheckout.churnReasons.notUsed",
        "selfCheckout.churnReasons.foundCheaper",
        "selfCheckout.churnReasons.foundBetter",
        "selfCheckout.churnReasons.noLongerNeeded",
        "selfCheckout.churnReasons.difficultToUse",
        "selfCheckout.churnReasons.billingProblems",
        "selfCheckout.churnReasons.notConfident",
        "selfCheckout.churnReasons.lackOfIntegrations",
        "selfCheckout.churnReasons.otherReason",
      ].map((textKey, index) => ({
        name: this.$t(textKey),
        key: textKey,
        id: index,
      }));
    },
    otherIsSelected() {
      return this.churnSelected.some(
        (item) => item.key === "selfCheckout.churnReasons.otherReason"
      );
    },
    filled() {
      return this.rating || this.churnSelected?.length;
    },
  },

  watch: {
    otherReason(otherReason) {
      if (
        otherReason &&
        !this.churnSelected.find(
          (item) => item.key === "selfCheckout.churnReasons.otherReason"
        )
      ) {
        this.churnSelected = this.churnSelected.concat([
          {
            name: this.$t("selfCheckout.churnReasons.otherReason"),
            key: "selfCheckout.churnReasons.otherReason",
            id: 9,
          },
        ]);
      } else if (
        !otherReason &&
        this.churnSelected.find(
          (item) => item.key === "selfCheckout.churnReasons.otherReason"
        )
      ) {
        this.churnSelected = this.churnSelected.filter(
          (item) => item.key !== "selfCheckout.churnReasons.otherReason"
        );
      }
    },
  },

  methods: {
    ...mapMutations(["setSnackBar"]),

    planFeaturesDifferences,

    close() {
      this.$emit("close");
    },

    goToNextStep() {
      this.step = this.nextStep[this.step];
    },

    async registerUserFeedback() {
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/company-plan-feedback`;

      this.loading = true;

      const payload = {
        action: "downgrade",
        feedback_rating: parseInt(this.rating) >= 0 ? this.rating : 3,
        feedback_note: "user_no_comment",
        feedback_sentences: [
          ...this.churnSelected.map((item) => item.name),
          this.otherReason,
        ].filter((value) => value),
        old_plan: JSON.stringify(this.actualCompanyPlan),
        new_plan: JSON.stringify(this.newCompanyPlan),
      };

      await this.$axios
        .post(url, payload, {
          headers: { Authorization: "Bearer " + this.token },
        })
        .catch((error) => {
          this.setSnackBar({
            message: "Erro ao registrar avaliação, por favor, tente novamente",
            color: "error",
            show: true,
          });
          console.error("registerUserFeedback", error);
        });

      this.loading = false;
    },

    async sendFeedback() {
      if (this.filled) {
        await this.registerUserFeedback();
      }
      this.goToNextStep();
    },
  },
};
</script>

<style>
v-toolbar__extension {
  padding: 0px !important;
}
</style>
