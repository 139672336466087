<template>
  <v-btn
    x-large
    color="error"
    elevation="0"
    block
    class="px-6 text-none text-body-1 font-weight-medium rounded-lg mt-4"
    @click="initHubspotChat"
  >
    <v-icon v-text="'ph-fill ph-warning'" left />
    {{ $t("action.reportError") }}
  </v-btn>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "ErrorReportButton",
  computed: {},
  methods: {
    ...mapActions(["verifyHubspotToken"]),
    async initHubspotChat() {
      await this.verifyHubspotToken();
    },
  },
};
</script>
