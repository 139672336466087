var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:`mt-4 ${_vm.center ? 'mx-auto' : ''} ${
    _vm.hasMoreThanOnePlan ? 'mb-8' : ''
  } elevation-0`,attrs:{"flat":"","tile":"","height":"300","max-width":_vm.listStyle ? 500 : 300}},[_c('v-card-subtitle',{staticClass:"text-subtitle-2 text-center text-uppercase"},[(_vm.isNewPlan)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("selfCheckout.newPlan")))]):_c('span',[_vm._v(_vm._s(_vm.hasMoreThanOnePlan ? `${_vm.$t("common.currentPlans")} (${_vm.currentPlansSize})` : _vm.$t("common.currentPlan")))])]),_c('v-carousel',{staticClass:"elevation-0",attrs:{"show-arrows":false,"hide-delimiter-background":"","hide-delimiters":_vm.isNewPlan || !_vm.hasMoreThanOnePlan,"light":!_vm.$vuetify.theme.dark,"continuous":"","cycle":_vm.hasMoreThanOnePlan && !_vm.isNewPlan,"interval":5000,"width":"100%","height":"290"},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},_vm._l((_vm.plansToShow),function(plan,i){return _c('v-carousel-item',{key:i},[_c('v-card',{style:(`${_vm.newPlan ? 'border: 1px solid orange;' : ''}`),attrs:{"outlined":""}},[_c('v-toolbar',{class:`mb-4 ma-0`,attrs:{"dense":"","flat":"","color":_vm.newPlan ? 'accent lighten-2' : 'grey lighten-3'}},[_c('v-toolbar-title',{staticClass:"text-body-1 font-weight-medium my-2"},[_vm._v(" "+_vm._s(plan.plan?.name || _vm.selectedPlanName)+" ")])],1),_c('v-card-title',{staticClass:"font-weight-bold align-end text-h5 my-0 py-0"},[(
              [plan.subscription_type, plan.status].includes(
                _vm.SUBSCRIPTIONS.TRIAL
              ) || _vm.trial
            )?_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("selfCheckout.trialPeriod"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.getTotalPrice(plan)) || "0,00")),(plan.subscription_type !== _vm.SUBSCRIPTIONS.FREE)?_c('span',{staticClass:"text-body-2 pb-1 ml-1"},[_vm._v("/ "+_vm._s(!!plan.subscription_type ? _vm.$t(plan.subscription_type) : _vm.$t(plan.type)))]):_vm._e()])]),(
            (plan.subscription_type === 'ANNUAL' || plan.type === 'ANNUAL') &&
            plan.discountText
          )?_c('v-card-subtitle',{staticClass:"green--text caption mx-4 font-weight-medium px-0 my-0 py-0"},[_vm._v(" "+_vm._s(`${_vm.$t("billing.discountOf")} ${plan.discountText}% ${_vm.$t( "billing.fromOriginalPrice" )}`)+" ")]):_vm._e(),_c('v-card-subtitle',{staticClass:"text-body-2 font-weight-medium py-0 mb-4 mt-2"},[(_vm.nextBilling)?_c('span',[_vm._v(" "+_vm._s(`${_vm.$t("common.from")} ${_vm.formatDateFullMonth(_vm.nextBilling)}`)+" ")]):(
              (plan.subscription_type === _vm.SUBSCRIPTIONS.TRIAL && !_vm.newPlan) ||
              _vm.trial
            )?_c('span',[_vm._v(" "+_vm._s(`${_vm.$t("common.until")} ${_vm.formatDateFullMonth(plan.end_trial)}`)+" ")]):_c('span',[_vm._v(" "+_vm._s(`${_vm.$t("common.since")} ${_vm.formatDateFullMonth( plan.init_billing )}`)+" ")])]),_c('v-divider'),_c('v-card-subtitle',{staticClass:"mt-5 text-body-2 font-weight-medium py-0"},[_vm._v(" "+_vm._s(_vm.$t("common.includes"))+" ")]),(_vm.listStyle)?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getListOfProducts(plan)),function(product,index){return _c('v-list-item',{key:index,staticClass:"py-0 my-0"},[_c('v-list-item-icon',{staticClass:"px-0 ml-0 mr-2"},[_c('v-icon',{attrs:{"dense":"","color":"success"},domProps:{"textContent":_vm._s('ph-fill ph-check-circle')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(product)+" ")])],1)],1)}),1):_c('v-card-actions',{staticClass:"px-4 mb-2 align-start"},_vm._l((_vm.getListOfProducts(plan)),function(product,index){return _c('span',{key:index,staticClass:"mr-1"},[_c('Logo',{attrs:{"height":"35","width":"35","product":product,"products":"","no-margins":""}})],1)}),0)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }