<template>
  <v-list
    :class="`text-left ${highlight} ${
      featured ? 'deep-purple lighten-5' : ''
    } ${clientHasThisPlan ? 'light-green lighten-5' : ''}`"
    :max-width="maxWidth"
  >
    <v-list-item
      v-for="(feature, i) in planFeatureDescription[plan.sku][serviceProvider]"
      :key="i"
    >
      <v-list-item-icon class="mr-2">
        <v-icon small color="accent">ph-check</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-subtitle class="text-wrap text-caption">{{
          $t(feature)
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { FEATURED_SKU } from "@/helpers/variables/modules";
import { planFeatureDescription } from "@/helpers/variables/productsDetails";
import { mapGetters } from "vuex";

export default {
  name: "PlanDescription",

  props: {
    plan: { type: Object, required: true },
    maxWidth: { type: String, default: "300" },
    highlight: { type: String, default: "" },
    clientHasThisPlan: { type: Boolean, default: false },
  },

  data() {
    return {
      planFeatureDescription,
      featured: this.plan.sku === FEATURED_SKU,
    };
  },

  computed: {
    ...mapGetters(["serviceProvider"]),
  },

  methods: {},
};
</script>
<style scoped></style>
